import { Link, useLocation } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import smallIcon from '../../../assets/images/logo.svg';
import './SideMenu.less';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/auth/AuthContext';
import { FormattedMessage, useIntl } from 'react-intl';
const { Sider } = Layout;

interface Props {
    collapsed: boolean;
    isMobile: boolean;
}

const SideMenu = ({ collapsed, isMobile }: Props) => {
    const location = useLocation();
    const intl = useIntl();
    const icons = require(`@ant-design/icons`);
    const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
    const [subMenuKey, setSubMenuKey] = useState<string>();
    const { authState } = useAuth();
    const [sideNavs, setSideNavs] = useState<any[]>();

    useEffect(() => {
        const selectedKeys = location.pathname.split('/');
        setSelectedKey(location.pathname);
        const expandedSubMenu = selectedKeys.length > 2 ? `/${selectedKeys[1]}` : undefined;
        setSubMenuKey(collapsed ? undefined : expandedSubMenu);
    }, [location.pathname, collapsed]);

    useEffect(() => {
        if (authState.sideNav && authState.sideNav.length > 0) {
            const sortedData = authState.sideNav.sort((a: any, b: any) => handleSort(a, b));
            const parentSideNavs: any[] = sortedData.filter(sn => !sn.parentId);

            const nestedSideNav: any[] = parentSideNavs.map(sn => {
                const parentSideNav = { ...sn };
                parentSideNav.child = sortedData.filter(c => c.parentId === sn.id);
                return parentSideNav;
            });
            setSideNavs(nestedSideNav);
        }
    }, [authState.sideNav])

    const handleSort = (a: any, b: any): number => {
        if (a.order > b.order) return 1;
        else if (a.order < b.order) return -1;
        return 0;
    }

    const handleSubMenuClick = (e: any) => {
        if (!e || (e && e.length === 0)) {
            setSubMenuKey(undefined);
        } else if (e && e.length > 1) {
            setSubMenuKey(e[1]);
        } else if (e && e.length > 0) {
            setSubMenuKey(e[0]);
        }
    }

    const getIcon = (iconName: string): any => {
        const AntdIcon = icons[iconName.trim()];
        return AntdIcon ? <AntdIcon /> : null;
    }

    const subMenus: any = sideNavs?.map((sideNav, index) => {
        const _tempContent: any = { key: `sm-dd-${index + 1}`, icon: getIcon(sideNav.icon) };
        return sideNav.child ? {
            ..._tempContent, label: intl.formatMessage({ id: sideNav.name }), children: sideNav.child.map((child: any, i: number) => {
                return { key: `sm-dd-${index + 1}-${i}`, label: <><Link to={child.route}>{intl.formatMessage({ id: child.name })}</Link></>, };
            })
        } : { ..._tempContent, label: <><Link to={sideNav.route}>{intl.formatMessage({ id: sideNav.name })}</Link></>, };

    }) ?? [];
    let items: any = [
        { key: 'sm-dd-0', icon: <DashboardOutlined />, label: <><Link to="/">{intl.formatMessage({ id: "DASHBOARD" })}</Link></> },
        ...subMenus
    ];

    const renderMenu = () => {
        return (
            <Menu theme="dark" mode="inline" triggerSubMenuAction="click" onOpenChange={handleSubMenuClick} items={items}
                selectedKeys={[selectedKey ? selectedKey : '/']} openKeys={subMenuKey ? [subMenuKey] : undefined}>
            </Menu>);
    }

    return (
        <Sider trigger={null} collapsible collapsed={collapsed} collapsedWidth={isMobile ? 0 : 64} width={256}>
            <div className={!collapsed ? "side-nav-logo" : "side-nav-logo collapsed"}>
                <img src={smallIcon} alt="small-icon" />
                <span className="logo-name"><FormattedMessage id="PORTAL_NAME" /></span>
            </div>
            {renderMenu()}
        </Sider>
    )
}

export default SideMenu;