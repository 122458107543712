import { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { routes, RouteInfo } from './routes';
import { useCookies } from "react-cookie";
import { GET_CURRENT_USER_WITH_SIDE_NAV, LOGOUT } from "./shared/graphql/auth/auth.gql";
import { useLazyQuery, useMutation } from "@apollo/client";
import './App.less';
import Dashboard from './pages/dashboard/Dashboard';
import Header from './shared/components/header/GeneralHeader';
import SideMenu from './shared/components/side-menu/SideMenu';
import Footer from './shared/components/footer/GeneralFooter';
import { Layout, Spin, notification } from 'antd';
import { CLEAR_CURRENT_USER, SET_CURRENT_USER, SET_LOGIN_STATUS, useAuth } from './hooks/auth/AuthContext';
import { CLEAR_NOTIFICATION, useLayout } from './hooks/layout/LayoutContext';
import { authClient, client } from './apollo-clients';
import useSpinner from './hooks/layout/useSpinner';
//Import i18n files
import messages_en from './assets/i18n/en.json';
import message_zh_CN from './assets/i18n/zh_CN.json';
import { IntlProvider } from 'react-intl';
import Cookies from 'universal-cookie';
import { randomKeyString } from './shared/helpers/json-display-helper';

const { Content } = Layout;

const App = () => {
  const cookiesUni = new Cookies();
  const [collapsed, setCollaped] = useState<boolean>(false);
  const [isMobile, seIsMobile] = useState<boolean>(false);
  const { innerWidth: width } = window;
  const navigate = useNavigate();
  const { authState, authDispatch } = useAuth();
  const { layoutState, layoutDispatch } = useLayout();
  const { setLoading } = useSpinner();
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies();
  const [signOut] = useMutation(LOGOUT, {
    client: authClient,
    errorPolicy: "ignore",
    onError: (err) => signOutCleanUp()
    //swallow error and proceed to clear profile
  });
  const [getCurrentUser, { loading, data, error, called, refetch }] = useLazyQuery(GET_CURRENT_USER_WITH_SIDE_NAV, {
    fetchPolicy: 'network-only',
    errorPolicy: "all"
  });

  const aToken: any = process.env.REACT_APP_ACCESS_TOKEN || 'sc-at';
  const rToken: any = process.env.REACT_APP_REFRESH_TOKEN || 'sc-rt';

  const messages: any = {
    'en': messages_en,
    'zh-CN': message_zh_CN
  };

  //Get user profile
  useEffect(() => {
    if (authState.isLogin) {
      called && refetch ? refetch() : getCurrentUser();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isLogin])

  const toggleSideNav = () => {
    setCollaped(!collapsed);
  };

  const logout = () => {
    signOut();
    signOutCleanUp();
  }

  const signOutCleanUp = () => {
    const isProd = process.env.NODE_ENV === "production";
    removeCookie(aToken, { path: "/", domain: isProd ? process.env.REACT_APP_COOKIE_DOMAIN : "localhost" });
    removeCookie(rToken, { path: "/", domain: isProd ? process.env.REACT_APP_COOKIE_DOMAIN : "localhost" });
    authDispatch({ type: CLEAR_CURRENT_USER });
    authDispatch({ type: SET_LOGIN_STATUS, payload: false });
    client.resetStore();
    authClient.resetStore();
    navigate('/login', { replace: true });
  }

  useEffect(() => {
    if (!cookiesUni.get(rToken)) {

      signOutCleanUp();
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiesUni.get(rToken)])

  //Set loading spinner
  useEffect(() => {
    setLoading(loading);// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  //Set user profile is profile data changed
  useEffect(() => {
    if (data && !error) {
      authDispatch({ type: SET_CURRENT_USER, payload: data });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])

  useEffect(() => {
    if (layoutState.showNotification && layoutState.showNotification.message) {
      openNotificationWithIcon();
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutState.showNotification])

  useEffect(() => {
    const token = cookies[rToken];
    authDispatch({ type: SET_LOGIN_STATUS, payload: token ? true : false });
    handleCollapsed();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const protectedRoutesAccessCheck = (route: RouteInfo) => {
    return (
      <Suspense fallback={<Spin />}>
        <route.component routes={route.routes} />
      </Suspense>);
  }

  const openNotificationWithIcon = () => {
    notification[layoutState?.showNotification?.type]({
      message: layoutState?.showNotification.message,
      description: layoutState?.showNotification?.description,
    });
    layoutDispatch({ type: CLEAR_NOTIFICATION })
  };

  const handleCollapsed = () => {
    if (width >= 768) setCollaped(false);
    else {
      seIsMobile(true);
      setCollaped(true);
    }
  }

  return (
    <IntlProvider locale={layoutState.locale} messages={messages[layoutState.locale]}>
      <Spin tip="Loading..." spinning={layoutState.showLoading}>
        <Layout className="app-container">
          {authState.isLogin && <SideMenu collapsed={collapsed} isMobile={isMobile} />}
          <Layout className="site-layout">
            {authState.isLogin && <Header collapsed={collapsed} toggleSideNav={toggleSideNav} logout={logout} />}
            <Content className="site-layout-background" >

              <Routes>
                <Route path="/" element={!authState.isLogin ?
                  <Navigate to="/login" /> : <Dashboard />} />
                {routes.map((route: RouteInfo, i) => {
                  return (
                    <Route key={randomKeyString()} path={route.path} element={
                      protectedRoutesAccessCheck(route)
                    } />
                  );
                })}
              </Routes>
            </Content>
            {authState.isLogin && <Footer />}
          </Layout>
        </Layout>
      </Spin>
    </IntlProvider>
  );
}

export default App;

