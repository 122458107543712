import React from "react";
import NoMatch from "./NoMatch";

export interface RouteInfo {
    path: string,
    component: any,
    routes?: RouteInfo[],
    lazy?: boolean,
    protected?: boolean
}

const ReactLazyPreload = (importStatement: any) => {
    const Component: any = React.lazy(importStatement);
    Component.preload = importStatement;
    return Component;
};

//preload pages
const SideNavigationForm = ReactLazyPreload(() => import('./pages/side-navigation/SideNavigationForm'));
SideNavigationForm.preload();
const SideNavigation = ReactLazyPreload(() => import('./pages/side-navigation/SideNavigation'));
SideNavigation.preload();
const SystemUserForm = ReactLazyPreload(() => import('./pages/system-user/SystemUserForm'));
SystemUserForm.preload();
const SystemUser = ReactLazyPreload(() => import('./pages/system-user/SystemUser'));
SystemUser.preload();
const SystemConfiguration = ReactLazyPreload(() => import('./pages/system-configuration/SystemConfiguration'));
SystemConfiguration.preload();
const ChangePasswords = ReactLazyPreload(() => import('./pages/settings/change-password/ChangePassword'));
ChangePasswords.preload();
const MyAccount = ReactLazyPreload(() => import('./pages/settings/my-account/MyAccount'));
MyAccount.preload();
const Role = ReactLazyPreload(() => import('./pages/role/Role'));
Role.preload();
const RoleForm = ReactLazyPreload(() => import('./pages/role/RoleForm'));
RoleForm.preload();
const Bank = ReactLazyPreload(() => import('./pages/banks/Bank'));
Bank.preload();
const BankForm = ReactLazyPreload(() => import('./pages/banks/BankForm'));
BankForm.preload();

const Transaction = ReactLazyPreload(() => import('./pages/transactions/Transaction'));
Transaction.preload();
const TransactionApproval = ReactLazyPreload(() => import('./pages/transactions/TransactionApproval'));
TransactionApproval.preload();
// const TransactionForm = ReactLazyPreload(() => import('./pages/transactions/TransactionForm'));
// TransactionForm.preload();
const Merchant = ReactLazyPreload(() => import('./pages/merchants/Merchant'));
Merchant.preload();

const Agent = ReactLazyPreload(() => import('./pages/agents/Agent'));
Agent.preload();
const AddAgent = ReactLazyPreload(() => import('./pages/agents/AgentForm'));
AddAgent.preload();
const AgentForm = ReactLazyPreload(() => import('./pages/agents/AgentForm'));
AgentForm.preload();
const MerchantForm = ReactLazyPreload(() => import('./pages/merchants/MerchantForm'));
MerchantForm.preload();
const BankAccount = ReactLazyPreload(() => import('./pages/bank-accounts/BankAccounts'));
BankAccount.preload();
const BankAccountForm = ReactLazyPreload(() => import('./pages/bank-accounts/BankAccountsForm'));
BankAccountForm.preload();
const BankAccountMerchantForm = ReactLazyPreload(() => import('./pages/bank-accounts/BankAccountsMerchantForm'));
BankAccountMerchantForm.preload();
const BankAccountAgentForm = ReactLazyPreload(() => import('./pages/bank-accounts/BankAccountsAgentForm'));
BankAccountAgentForm.preload();
const SystemUserLog = ReactLazyPreload(() => import('./pages/logs/system-user/SystemUserLog'));
SystemUserLog.preload();
const TransactionLog = ReactLazyPreload(() => import('./pages/logs/transaction/TransactionLog'));
TransactionLog.preload();
const MerchantLog = ReactLazyPreload(() => import('./pages/logs/merchant/MerchantLog'));
MerchantLog.preload();

const ExportLog = ReactLazyPreload(() => import('./pages/logs/export/ExportLog'));
ExportLog.preload();
const BankAccountLog = ReactLazyPreload(() => import('./pages/logs/bank-account/BankAccountLog'));
BankAccountLog.preload();
const Settlement = ReactLazyPreload(() => import('./pages/transactions/Settlement'));
Settlement.preload();
const SettlementApproval = ReactLazyPreload(() => import('./pages/transactions/SettlementApproval'));
SettlementApproval.preload();
const SettlementForm = ReactLazyPreload(() => import('./pages/transactions/SettlementForm'));
SettlementForm.preload();
const AgentLog = ReactLazyPreload(() => import('./pages/logs/agent/AgentLog'));
AgentLog.preload();
const Adjustment = ReactLazyPreload(() => import('./pages/adjustments/Adjustment'));
Adjustment.preload();
const AdjustmentForm = ReactLazyPreload(() => import('./pages/adjustments/AdjustmentForm'));
AdjustmentForm.preload();
const BalanceHistory = ReactLazyPreload(() => import('./pages/balance-history/BalanceHistory'));
BalanceHistory.preload();
const CryptoAddress = ReactLazyPreload(() => import('./pages/crypto-addresses/CryptoAddress'));
CryptoAddress.preload();
const CryptoAddressForm = ReactLazyPreload(() => import('./pages/crypto-addresses/CryptoAddressForm'));
CryptoAddressForm.preload();
const TransactionSupport = ReactLazyPreload(() => import('./pages/transaction-support/TransactionSupport'));
TransactionSupport.preload();
const DailyStatement = ReactLazyPreload(() => import('./pages/report/DailyStatement'));
DailyStatement.preload();
const TNGAccount = ReactLazyPreload(() => import('./pages/tngs/TNGAccount'));
TNGAccount.preload();
const TNGAccountForm = ReactLazyPreload(() => import('./pages/tngs/TNGAccountForm'));
TNGAccountForm.preload();
const DuitNow = ReactLazyPreload(() => import('./pages/duit-now/DuitNow'));
DuitNow.preload();
const DuitNowForm = ReactLazyPreload(() => import('./pages/duit-now/DuitNowForm'));
DuitNowForm.preload();


export const routes: RouteInfo[] = [
    {
        path: "/login",
        component: React.lazy(() => import('./pages/login/Login'))
    }, {
        path: "/transactions/settlement/add-settlement",
        component: SettlementForm,
        protected: true
    }, {
        path: "/transactions/settlement",
        component: Settlement,
        protected: true
    }, {
        path: "/transactions/pay-out",
        component: Transaction,
        protected: true
    }, {
        path: "/transactions/pay-in",
        component: Transaction,
        protected: true
    }, {
        path: "/logs/bank-accounts",
        component: BankAccountLog,
        protected: true
    }, {
        path: "/logs/export",
        component: ExportLog,
        protected: true
    }, {
        path: "/logs/merchants",
        component: MerchantLog,
        protected: true
    }, {
        path: "/logs/system-users",
        component: SystemUserLog,
        protected: true
    }, {
        path: "/logs/transactions",
        component: TransactionLog,
        protected: true
    }, {
        path: "/logs/agents",
        component: AgentLog,
        protected: true
    }, {
        path: "/reports/daily-statement",
        component: DailyStatement,
        protected: true
    }, {
        path: "/actions/settlement",
        component: SettlementApproval,
        protected: true
    }, {
        path: "/actions/pay-out",
        component: TransactionApproval,
        protected: true
    }, {
        path: "/actions/pay-in",
        component: TransactionApproval,
        protected: true
    }, {
        path: "/admin/side-navigation/add-side-navigation",
        component: SideNavigationForm,
        protected: true
    }, {
        path: "/admin/side-navigation/edit-side-navigation/:id",
        component: SideNavigationForm,
        protected: true
    }, {
        path: "/admin/side-navigation",
        component: SideNavigation,
        protected: true
    }, {
        path: "/admin/system-users/edit-system-user/:id",
        component: SystemUserForm,
        protected: true
    }, {
        path: "/admin/system-users/add-system-user",
        component: SystemUserForm,
        protected: true
    }, {
        path: "/admin/system-users",
        component: SystemUser,
        protected: true
    }, {
        path: "/admin/configuration",
        component: SystemConfiguration,
        protected: true
    }, {
        path: "/admin/banks/edit-bank/:id",
        component: BankForm,
        protected: true
    }, {
        path: "/admin/banks/add-bank",
        component: BankForm,
        protected: true
    }, {
        path: "/admin/banks",
        component: Bank,
        protected: true
    }, {
        path: "/admin/adjustment/add-adjustment",
        component: AdjustmentForm,
        protected: true
    }, {
        path: "/admin/adjustment",
        component: Adjustment,
        protected: true
    }, {
        path: "/admin/transaction-support",
        component: TransactionSupport,
        protected: true
    }, {
        path: "/settings/change-password",
        component: ChangePasswords,
        protected: true
    }, {
        path: "/settings/my-account",
        component: MyAccount,
        protected: true
    }, {
        path: "/admin/role/edit-role/:id",
        component: RoleForm,
        protected: true
    }, {
        path: "/admin/role/add-role",
        component: RoleForm,
        protected: true
    }, {
        path: "/admin/roles",
        component: Role,
        protected: true
    }, {
        path: "/merchants/tng-account/edit-tng-account/:id",
        component: TNGAccountForm,
        protected: true
    }, {
        path: "/merchants/tng-account/add-tng-account",
        component: TNGAccountForm,
        protected: true
    }, {
        path: "/merchants/tng-account",
        component: TNGAccount,
        protected: true
    }, {
        path: "/merchants/duit-now/edit-duit-now-account/:id",
        component: DuitNowForm,
        protected: true
    }, {
        path: "/merchants/duit-now/add-duit-now-account",
        component: DuitNowForm,
        protected: true
    }, {
        path: "/merchants/duit-now",
        component: DuitNow,
        protected: true
    }, {
        path: "/merchants/merchants/edit-merchant/:id",
        component: MerchantForm,
        protected: true
    }, {
        path: "/merchants/merchants/add-merchant",
        component: MerchantForm,
        protected: true
    }, {
        path: "/merchants/merchants",
        component: Merchant,
        protected: true
    }, {
        path: "/merchants/balance-history",
        component: BalanceHistory,
        protected: true
    }, {
        path: "/merchants/agents",
        component: Agent,
        protected: true
    }, {
        path: "/merchants/agents/edit-agent/:id",
        component: AgentForm,
        protected: true
    }, {
        path: "/merchants/agents/add-agent/",
        component: AddAgent,
        protected: true
    }, {
        path: "/merchants/bank-accounts/link-merchants/:id",
        component: BankAccountMerchantForm,
        protected: true
    }, {
        path: "/merchants/bank-accounts/link-agents/:id",
        component: BankAccountAgentForm,
        protected: true
    }, {
        path: "/merchants/bank-accounts/edit-bank-accounts/:id",
        component: BankAccountForm,
        protected: true
    }, {
        path: "/merchants/bank-accounts/add-bank-accounts",
        component: BankAccountForm,
        protected: true
    }, {
        path: "/merchants/bank-accounts",
        component: BankAccount,
        protected: true
    }, {
        path: "/merchants/crypto-address/edit-crypto-address/:id",
        component: CryptoAddressForm,
        protected: true
    }, {
        path: "/merchants/crypto-address/add-crypto-address",
        component: CryptoAddressForm,
        protected: true
    }, {
        path: "/merchants/crypto-address",
        component: CryptoAddress,
        protected: true
    }, {
        path: "*",
        component: NoMatch
    }
];