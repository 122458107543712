import { gql } from "@apollo/client";

export const GET_SIGNUP_COUNTRIES = gql`
query SignUpCountries{
    signUpCountries{
        id,
        dialCode,
        name
    }
}
`

export const GET_COUNTRIES = gql`
query Countries{
    countries{
        id,
        dialCode,
        name,
        currencyCode
    }
}
`;